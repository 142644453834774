(function (factory) {
    typeof define === 'function' && define.amd ? define(factory) :
    factory();
}(function () { 'use strict';

    jQuery(function ($) {
        console.log('smm scripts loaded');

        function faqToggle(e){
            let target = $(e.target);
            if( target.hasClass('faq-question-text') ){
                target = target.parent();
            }

            if( target.parent().hasClass('active') ){
                target.parent().removeClass('active');
            } else{
                $('.faq-item').each(function(){
                    $(this).removeClass('active');
                });
                target.parent().addClass('active');
            }
        }
        $('.faq-question-text, .faq-question').on('click', function(e){
            e.stopPropagation();
            faqToggle(e);
        });
        

        $(document).on('click', function(e){
            e.stopPropagation();
            let target = $(e.target);

            // console.log(target)
            if( target.attr('id') == 'mobile-nav-toggle' || target.hasClass('menu-item')){
                $('#mobile-nav').toggleClass('active');
                $('body').toggleClass('mobile-nav-active');
                $('#mobile-nav-toggle').toggleClass('active');
            } else if( target.hasClass('mobile-nav-active') ){
                $('#mobile-nav').removeClass('active');
                $('body').removeClass('mobile-nav-active');
                $('#mobile-nav-toggle').removeClass('active');
            }
        });
        // $('#mobile-nav-toggle').on('click', function(e){
        //     $('#mobile-nav').toggleClass('active');
        //     $('body').toggleClass('mobile-nav-active');
        //     $(this).toggleClass('active');
        // })
        
    });

}));
